<template>
  <div
    class="banner">
    <div
      class="banner-cta">
      <div>
        <h2
          class="title"
          :class="{ 'show': client && referralCampaign }">
          {{ headerText(client && client.firstName, referralCampaign) }}
        </h2>
        <p class="subtitle">
          {{ subheaderText }}
        </p>
        <p class="body">
          {{ bodyText }}
        </p>
        <primary-button class="button">
          <a :href="getStyleQuizUrl(referralCampaign)">
            Take your style quiz
          </a>
        </primary-button>
      </div>
    </div>
    <img
      class="banner-image"
      :src="getImageUrl(`referral/referral-${isMobile ? 'mobile' : 'desktop'}-alt.jpg`)">
  </div>
</template>

<script>
import PrimaryButton from '@/homepage/components/common/PrimaryButton.vue'
import { getImageUrl } from '@/homepage/utils/url.js'
import { headerText, subheaderText, bodyText } from '@/homepage/utils/referrals.js'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

export default {
  name: 'ReferralBanner',
  components: { PrimaryButton },
  props: {
    client: {
      type: Object,
      default: null
    },
    referralCampaign: {
      type: Object,
      default: null
    }
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    return {
      getImageUrl,
      headerText,
      getStyleQuizUrl,
      subheaderText,
      bodyText
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 64px;

  @include medium {
    flex-direction: row;
    margin-top: 80px;
  }

}

.banner-cta {
  display: flex;
  align-items: center;
  background-color: rgba($champagne, .8);
  width: 100%;
  padding: 1em;

  @include medium {
    width: 50%;
    padding: 2em;
  }

}

.banner-image {
  object-fit: cover;
  background-color: $white;
  width: 100%;

  @include medium {
    width: 50%;
  }

}

.title {
  opacity: 0;
  transform: translateY(12px);

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  transition: all 500ms ease;
}

.subtitle {
  font-size: 1.1em;
  font-weight: $font-weight-semibold;
  margin-bottom: 0;
}

.body {
  margin-top: .5em;
}

.hidden {
  visibility: hidden;
}

.button {
  margin: 1em 0;
}
</style>
