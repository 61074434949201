<template>
  <div class="row bg-origami pb-xl">
    <HomepageSection title="Our Top Curated Collections"/>
    <MyClosetSectionPreview
      :section-id="heroCollectionId"
      class="col-12 pb-lg-xl"/>
    <div
      v-if="tileCollections.length > 0"
      class="tile-grid">
      <div
        v-for="collection in tileCollections"
        :key="collection.id">
        <CuratedCollectionsImageTile
          :collection="collection"/>
      </div>
    </div>
    <div
      v-else
      class="tile-grid">
      <ImageTileButtonPlaceholder
        v-for="i in 4"
        :key="`placeholder-${i}`"/>
    </div>
  </div>
</template>
<script setup>
import { computed, provide } from 'vue'
import MyClosetSectionPreview from '@/components/closet/MyClosetSectionPreview.vue'
import { useClosetStore } from '@shared/stores/closet.js'
import { getSectionHref } from '@shared/utils/route.js'
import { storeToRefs } from 'pinia'
import CuratedCollectionsImageTile from './CuratedCollectionsImageTile.vue'
import HomepageSection from './revamp/HomepageSection.vue'
import ImageTileButtonPlaceholder from '@/components/global/ImageTileButtonPlaceholder.vue'

const closetStore = useClosetStore()
const { homepageSections } = storeToRefs(closetStore)

const collections = computed(() => homepageSections.value?.map(section => {
  return {
    id: section.id,
    title: section.name,
    imageUrl: section.bannerImageUrl,
    routeLocation: getSectionHref(section)
  }
}) ?? [])
const heroCollectionId = computed(() => collections.value[0]?.id ?? null)
const tileCollections = computed(() => collections.value.slice(1) || null)

provide('useClosetDetailFlyout', false)
</script>

<style lang="scss" scoped>
.tile-grid {
  display: grid;
  gap: $spacing-md;
  @include media-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-tablet {
    grid-template-columns: repeat(2, 1fr);
    margin-top: $spacing-md;

  }
  @include media-mobile {
    grid-template-columns: 1fr;
    margin-top: $spacing-md;
  }
}
</style>
