<template>
  <div id="header">
    <div id="header-container">
      <router-link
        :to="{ name: 'home' }"
        class="logo">
        <homepage-image
          alt="Armoire"
          role="link"
          class="logo"
          width="100%"
          height="100%"
          webp-source="logo-155.webp"
          source="logo.jpg"
          source-type="image/jpeg"/>
      </router-link>
      <div id="header-buttons">
        <plain-button
          v-if="!useMobileLayout"
          target="_self"
          to="/howitworks/"
          type="router-link"
          label="How it Works"
          class="header-button">
          <p>
            How it Works
          </p>
        </plain-button>
        <plain-button
          v-if="!useMobileLayout"
          target="_self"
          to="/frequently-asked-questions/"
          type="router-link"
          label="FAQ"
          class="header-button">
          <p>
            FAQ
          </p>
        </plain-button>
        <plain-button
          v-if="!useMobileLayout"
          target="_self"
          to="/howitworks/#plans"
          type="router-link"
          label="Plans"
          class="header-button">
          <p>
            Plans
          </p>
        </plain-button>
        <plain-button
          v-if="!useMobileLayout"
          target="_self"
          to="/#brands"
          type="router-link"
          label="Brands"
          class="header-button">
          <p>
            Brands
          </p>
        </plain-button>
        <plain-button
          target="_self"
          href="/home"
          type="a"
          label="Log In"
          class="header-button">
          <p>
            Log In
          </p>
        </plain-button>
        <primary-button
          v-if="isMobile"
          id="get-started-header"
          :href="getStyleQuizUrl()"
          @click="trackClickEvent('Homepage', 'Navbar Get Started')">
          Start
        </primary-button>
        <get-started-button
          v-else
          id="get-started-header"
          location="Navbar"/>
      </div>
    </div>
  </div>
</template>

<script>
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'
import PrimaryButton from './PrimaryButton.vue'
import HomepageImage from './HomepageImage.vue'
import PlainButton from './PlainButton.vue'
import GetStartedButton from './GetStartedButton.vue'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    PrimaryButton,
    PlainButton,
    HomepageImage,
    GetStartedButton
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    const { trackClickEvent } = useAnalytics()
    return {
      getStyleQuizUrl,
      trackClickEvent
    }
  },
  computed: {
    useMobileLayout () {
      return this.windowWidth < 1000
    }
  }
}

</script>

<style lang="scss" scoped>
#header {
  margin: 0px;
  position: fixed;
  top: 0;
  height: 80px;
  z-index: 50;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#header-container {
  margin: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include medium {
    margin: 16px;
  }
}

#header-buttons {
  display: flex;
  align-items: center;

  .header-button {
    margin-right: 12px;
    font-size: 14px;
    font-family: "Montserrat", "Helvetica", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .2em;
    p {
      margin-bottom: 0;
    }
    @include medium {
      margin-right: 24px;
    }
  }
}

.logo {
  height: 24px;
  width: 137px;
}
</style>
