import marked from 'marked'

// "foo_bar", "foo bar", "fooBar", or "foo-bar" to "Foo Bar"
export function toTitleCase (str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[-_ ]/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
}

// "foo_bar", "foo bar", "fooBar", or "foo-bar" to "FooBar"
export function toPascalCase (str) {
  return str.replace(/[-_ ]/g, ' ').replace(/\b\w/g, l => l.toUpperCase()).replace(/ /g, '')
}

// "foo_bar", "foo bar", or "fooBar" to "foo-bar"
export function toKebabCase (str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().replace(/[_ ]/g, '-')
}

// foo_bar -> Foo Bar
export function underscoreToCapitalized (val) {
  return val.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
}

// Foo Bar -> foo_bar
export function capitalizedToUnderscore (val) {
  return val.toLowerCase().replace(/ /g, '_')
}

// Foo Bar -> foo-bar
export function capitalizedToDash (val) {
  return val.toLowerCase().replace(/ /g, '-')
}

// Adds space before each capital letter
export function addSpaceBeforeCapitals (str) {
  return str.replace(/([A-Z])/g, ' $1').trim()
}
export function isLowerCase (str) {
  return str === str.toLowerCase() && str !== str.toUpperCase()
}

// Turns an array of strings into an array of Objects in the format:
// { label: 'One Two', value: 'one_two' }
export function parseOptions (options) {
  if (!options.length) return []

  const s = options[0]
  if (typeof s !== 'string') return options

  if (isLowerCase(s)) {
    return options.map(x => {
      return {
        label: x.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        value: x
      }
    })
  } else {
    return options.map(x => {
      return {
        label: x,
        value: x.replace(/\b\w/g, l => l.toLowerCase()).replace(/ /g, '_')
      }
    })
  }
}

export function removeLineBreaksFromHyphenatedText (text) {
  const hyphenated = text.match(/(?:\w+-)+\w+/g)
  if (hyphenated) {
    let hyphenatedWord = ''
    for (let r = 0; r < hyphenated.length; r++) {
      hyphenatedWord = hyphenated[r]
      text = text.replace(hyphenatedWord, ('<nobr>' + hyphenatedWord + '</nobr>'))
    }
  }
  return text
}

export function addLineBreakBeforeParentheses (text) {
  return text.replace(/ \(/g, '<br>(')
}

export function formatPlanName (planName) {
  planName = removeLineBreaksFromHyphenatedText(planName)
  return addLineBreakBeforeParentheses(planName)
}

export function getImageUrl (image, newSize = 'd') {
  if (!image) return ''

  return image.processed
    ? image.url.slice(0, image.url.length - 5) + newSize + '.jpg'
    : image.url
}

export function formatPrice (price, noDecimal = false, dollarOnly = false, invalidEmptyString = false) {
  if (typeof (price) === 'undefined' || price === null) return invalidEmptyString ? '' : 'Not available'
  let displayPrice = dollarOnly ? price : price / 100
  if (noDecimal) {
    displayPrice = Math.round(displayPrice)
    return `$${new Intl.NumberFormat().format(displayPrice)}`
  }
  if (isNaN(displayPrice)) return ''
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(displayPrice)
}

export function toLocaleDateString (str) {
  return (new Date(Date.parse(str))).toLocaleDateString()
}

// Returns date string in format: 1/1
export function toDayMonthString (str) {
  if (str === null) return '...'
  const date = new Date(Date.parse(str))
  return `${date.getMonth() + 1}/${date.getDate()}`
}

// Returns date string in format: 1/1/2020
export function toDayMonthYearString (str) {
  if (str === null) return '...'
  const date = new Date(Date.parse(str))
  return `${toDayMonthString(str)}/${date.getFullYear()}`
}

// Returns date string in format: September 1
export function toMonthDayString (str, monthLength = 'long') {
  if (str === null) return '...'
  const date = new Date(Date.parse(str))
  const month = date.toLocaleString('default', { month: monthLength })
  return `${month} ${date.getDate()}`
}

// Returns date string in format: September 2020
export function toMonthYearString (str) {
  if (!str) return '...'
  const date = new Date(Date.parse(str))
  const month = date.toLocaleString('default', { month: 'long' })
  return `${month} ${date.getFullYear()}`
}

// Returns time string in format: 12:00 AM
export function toHourMinuteString (str) {
  const date = new Date(Date.parse(str))
  return date.toLocaleTimeString('default', {
    hour: 'numeric',
    minute: '2-digit'
  })
}

// Returns date string in format: September 1, 2020
export function toMonthDayYearString (str) {
  if (str === null) return '...'
  const date = new Date(Date.parse(str))
  const year = date.getFullYear()
  return `${toMonthDayString(str)}, ${year}`
}

export function toEndOfDayInTimezone (dateStr) {
  const timezoneOffset = new Date().getTimezoneOffset() / 60
  return `${dateStr}T23:59:59.999-0${timezoneOffset}:00`
}

export function toDifferenceTime (str) {
  const then = new Date(Date.parse(str))
  const now = new Date()
  const diffMs = (now - then)
  const diffMins = Math.floor(diffMs / 1000 / 60)
  if (diffMins < 1) {
    return 'Just now'
  }
  if (diffMins < 60) {
    return `${diffMins}m`
  }
  const diffHrs = Math.floor(diffMs / 1000 / 60 / 60)
  if (diffHrs < 24) {
    return `${diffHrs}h`
  }
  const diffDays = Math.floor(diffMs / 1000 / 60 / 60 / 24)
  if (diffDays < 365) {
    return `${diffDays}d`
  }
  return `${Math.abs(Math.round(diffDays / 365.25))}y`
}

export function capitalizeFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
export function slugify (string) {
  if (!string) return ''

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function parseMarkdown (string, stripEnclosingParagraph = false) {
  let markdownString = marked(string)
  if (stripEnclosingParagraph) {
    if (markdownString.startsWith('<p>')) {
      markdownString = markdownString.slice(3)
    }
    if (markdownString.endsWith('</p>')) {
      markdownString = markdownString.slice(0, markdownString.length - 3)
    } else if (markdownString.endsWith('</p>', markdownString.length - 1)) {
      markdownString = markdownString.slice(0, markdownString.length - 5)
    }
  }
  return markdownString
}

// Returns a string of the form "1, 2, and 3" from an array of strings
export function arrayToSentence (arr) {
  if (arr.length === 0) return ''
  if (arr.length === 1) return arr[0]

  if (arr.length > 1) {
    const last = arr[arr.length - 1]
    const prev = arr.slice(0, -1)
    return prev.join(', ') + ' and ' + last
  }
}

export function truncate (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length - suffix.length) + suffix
  } else {
    return text
  }
}
