<template>
  <HeroImage ref="heroImage"/>
  <RentingMadeEasySection ref="rentingMadeEasy"/>
  <CuratedCollections/>
  <FavoriteBrandsSection id="brands"/>
  <PersonalStylingSection/>
  <InTheNewsSection/>
  <PlansForEveryBudget id="plans"/>
  <WhatMembersSay/>
  <EmailCaptureSection/>
</template>

<script setup>
import { onActivated, ref, watch, computed } from 'vue'
import { useElementVisibility } from '@/composables/element'
import HeroImage from '@/homepage/components/revamp/HeroImage.vue'
import RentingMadeEasySection from '@/homepage/components/revamp/RentingMadeEasySection.vue'
import CuratedCollections from '@/homepage/components/CuratedCollections.vue'
import PlansForEveryBudget from '@/homepage/components/revamp/PlansForEveryBudget.vue'
import PersonalStylingSection from '@/homepage/components/revamp/PersonalStylingSection.vue'
import EmailCaptureSection from '@/homepage/components/revamp/EmailCaptureSection.vue'
import InTheNewsSection from '@/homepage/components/revamp/InTheNewsSection.vue'
import FavoriteBrandsSection from '@/homepage/components/revamp/FavoriteBrandsSection.vue'
import WhatMembersSay from '@/homepage/components/revamp/WhatMembersSay.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useHomepageStore } from '@/store/modules/homepage'

const { trackPageView } = useAnalytics()

onActivated(() => {
  trackPageView('Homepage')
})

const homepageStore = useHomepageStore()
const rentingMadeEasy = ref(null)
const heroImage = ref(null)
const rentingMadeEasyVisible = useElementVisibility(rentingMadeEasy)
const heroImageVisible = useElementVisibility(heroImage)
const elementsAreVisible = computed(() => rentingMadeEasyVisible.value || heroImageVisible.value)

watch(elementsAreVisible, (value) => {
  homepageStore.showMobileFooter = !value
})
</script>

<style scoped lang="scss">
#brands, #plans { @include anchor-link; }
</style>
