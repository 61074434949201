<!-- Current collection  button with a banner image that links to the section page, in a light color scheme -->
<template>
  <ImageTileButton
    :title="collection.title"
    :images="[collection.imageUrl]"
    :image-count="1"
    :truncate-text="false"
    :collapse-subtitle="true"
    :to="collection.routeLocation"
    @click="emit('click', $event)"/>
</template>

<script setup>
import ImageTileButton from '@/components/global/ImageTileButton.vue'

defineProps({
  collection: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
</script>
<style lang="scss" scoped>
:deep(.overlay) {
  background-color: rgba(247, 245, 247, 0.75) !important;

}
.tile-button {
  aspect-ratio: unset !important;
  height: 100% !important;
  max-height: 166px !important;
}
</style>
