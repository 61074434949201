<template>
  <div
    class="row py-xl"
    :class="`bg-${variant}`">
    <HomepageSection>
      <template #default>
        <AutoscrollCarousel
          class="w-100"
          :items="rentalSteps"
          :items-per-slide="{mobile: 1, tablet: 2, desktop: 4}">
          <template #default="{ item }">
            <div class="step text-center">
              <h3 class="caps">
                <span
                  v-for="(part, index) in item.titleParts"
                  :key="index">
                  {{ part }}
                </span>
              </h3>
              <p class="description pb-lg">
                {{ item.description }}
              </p>
            </div>
          </template>
        </AutoscrollCarousel>
        <GetStartedButton
          location="Renting Made Easy"
          fixed-width
          class="mt-lg"/>
      </template>
    </HomepageSection>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import AutoscrollCarousel from '@shared/components/ADORN/AutoscrollCarousel.vue'
import HomepageSection from '@/homepage/components/revamp/HomepageSection.vue'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'

defineProps({
  variant: {
    default: 'white',
    validator: v => v.match(/(white|origami)/),
    type: String
  }
})

const rentalSteps = computed(() => [
  {
    titleParts: ['Take our ', 'Style Quiz'],
    description: 'Help us get to know you better with a quick 5-minute quiz.'
  },
  {
    titleParts: ['Choose ', 'your styles'],
    description: 'Select items you love from your closet. We\'ll ship them to your door.'
  },
  {
    titleParts: ['Wear', 'and enjoy'],
    description: 'Keep your styles as long as you want. Laundry is always on us!'
  },
  {
    titleParts: ['Swap', 'easily'],
    description: 'When you\'re ready to swap. Enjoy your styles until your new case arrives.'
  }
])
</script>

<style lang="scss" scoped>
  .caps span {
    display: block;
  }
  .step {
    display: flex;
    flex-direction: column;
    min-height: 150px;
  }
  .description {
    margin: 0 auto;
    width: 204px;
  }
</style>
