<template>
  <div
    class="row hero-container"
    :style="{ backgroundImage: `url('${section?.blocks[0]?.image.preprocessedUrl}')` }">
    <div class="hero-window col-lg-6"/>
    <div class="hero-content col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <div class="content-wrapper col-lg-8">
        <h1 class="hero-header text-capitalize my-lg-lg me-sm mt-sm-xs">
          {{ section?.title }}
        </h1>
        <p class="subtitle my-lg">
          {{ section?.blocks[0]?.text }}
        </p>
        <GetStartedButton
          location="Hero Image"
          :fixed-width="isTabletOrSmaller"
          :class="{ 'w-75': !isTabletOrSmaller }"
          class=" mb-md mb-lg-0"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGenericContentStore } from '@/store/modules/genericContent'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import { computed } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'

const { isTabletOrSmaller } = useScreenSize()

const store = useGenericContentStore()
const section = computed(() => store.heroImageSection)
</script>

<style lang="scss" scoped>
.hero-container {
  background-size: cover;

  @include media-desktop {
    height: 100%;
    max-height: 600px;
  }
  @include media-tablet-or-smaller {
    background-position: 20% top;
  }
}

.hero-window {
  @include media-tablet-or-smaller {
    aspect-ratio: 3 / 2;
  }
}

.hero-content {
  background-color: rgba($origami, 0.75);

  @include media-tablet-or-smaller {
    padding: 1rem;
    align-items: center;
    text-align: center;
  }
}

.content-wrapper {
  word-wrap: break-word;
  white-space: normal;
}
</style>
